import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputBase,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TextFieldProps,
  Theme,
  Typography
} from '@mui/material';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import ButtonCustom from 'src/components/Button/Button';
import { buttonColor } from 'src/constants/styles';
import ButtonInherit from '../Button/ButtonInherit';
import IconButtonOptions from '../Button/IconButtonOption';
import ImageUploadProfile from '../ProfileCardImage/ImageUploadProfile';

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: '#ffffff',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent'
    }
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  textHelp: {
    color: '#65676b',
    fontSize: 11,
    fontFamily: 'inherit'
  },
  noOptionsText: {
    fontSize: '15px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    color: '#8a8d91'
  },
  root: {
    padding: '15px 20px 20px 20px',
    backgroundColor: '#fff',
    borderRadius: 10,
    width: '100%',
    marginTop: 10
  },
  autocomplete: {
    marginTop: '10px !important'
  },
  error_video: {
    color: '#F44336 !important',
    fontSize: '13px !important',
    paddingLeft: '6px'
  },

  styleInput: {
    width: ' 100%',
    height: '61px !important',
    marginTop: 'unset !important',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#e2e2e1',
    justifyContent: 'center',
    '& .MuiInputBase-input': {
      textAlign: 'start',
      fontSize: '18px !important',
      height: '50px !important',
      marginTop: '0px !important',
      marginLeft: '0px !important',
      width: '100% !important',
      position: 'unset !important',
      borderRadius: '8px !important',
      paddingLeft: '8px !important',
      paddingBottom: '0px !important',
      backgroundColor: 'unset !important'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:before,& .MuiInput-underline:after': {
      borderBottom: 'none'
    }
  },
  styleInputPriceProduct: {
    width: ' 100%',
    height: '45px !important',
    marginTop: 'unset !important',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#e2e2e1',
    justifyContent: 'center',
    '& .MuiInputBase-input': {
      textAlign: 'start',
      fontSize: '16px !important',
      height: '50px !important',
      marginTop: '0px !important',
      marginLeft: '0px !important',
      width: '100% !important',
      position: 'unset !important',
      borderRadius: '8px !important',
      paddingLeft: '8px !important',
      paddingBottom: '0px !important',
      backgroundColor: 'unset !important'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:before,& .MuiInput-underline:after': {
      borderBottom: 'none'
    }
  },
  listAvatar: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginRight: '10px'
  },
  itemFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  itemOptions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 5px 10px 0px'
  },
  optionsAll: {
    margin: '5px 5px 10px 0px'
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '16px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {},
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bdbdbd',
      borderRadius: 10
    }
  }
}));

function MyFormHelperText(props: any) {
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return (
        <Typography variant="body2" sx={{ fontSize: 12 }}>
          {props.helpText}
        </Typography>
      );
    }

    return '';
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

function SocialFieldUpdate(props) {
  const {
    formik,
    fieldUpdate,
    infoUpdate,
    loading,
    setLoading,
    buttonSeleted,
    setVerify,
    spacing,
    iconCheckPage,
    size,
    styleContainer,
    currentHostAdd,
    currenHostAddProject,
    setActionUpdate,
    setNotificationLeave,
    setErrorItem
  } = props;

  const theme = useTheme();
  const [tempString, setTempString] = useState<any>([{ key: '', value: '' }]);
  const textInput = React.useRef<any>(null);
  const classes = useStyles();
  const [types, setType] = React.useState('');
  const [loadingImageUpload, setLoadingImageUpload] = useState<any>(false);
  const [errorImage, setErrorImage] = useState<string>('');
  const [errorMp3, setErrorMp3] = useState<string>('');

  const handleInputChange = (value, item) => {
    debounceFilterOptions(value, item);
  };
  const debounceFilterOptions = React.useCallback(
    _.debounce((inputValue, item) => {
      formik.setFieldValue(item.name, inputValue);
      if (inputValue && item?.search) {
        item?.setList([]);
        item?.setLoading(true);
        item?.fetchData(inputValue, item?.suggest_query);
      }
      if (!inputValue && formik.values[item.name].length === 0 && item.search) {
        item?.setList([]);
      }
    }, 500),
    []
  );
  const debounceTextfield = React.useCallback(
    _.debounce((name, value) => {
      formik.setFieldValue(name, value);
    }, 500),
    []
  );
  const handleSearchParams = (el: any, keySearch: string) => {
    debounceSearchParams(el, keySearch);
  };
  const debounceSearchParams = React.useCallback(
    _.debounce((el, keySearch) => {
      el?.fetchData({ keyword: keySearch });
    }, 450),
    []
  );

  const distributeStatusHost = (el: any, itemStatus: string) => {
    if (itemStatus === 'pending') {
      return formik.values[el.name]?.filter(item => item.status === 'pending');
    } else if (itemStatus === 'rejected') {
      return formik.values[el.name]?.filter(item => item.status === 'rejected');
    } else {
      return formik.values[el.name]?.filter(item => item.status === 'approved');
    }
  };
  const renderField = (el: any, index: any) => {
    switch (el.field_type) {
      case 'textField':
        return (
          <Grid key={index} item xs={el?.span ? el.span : 12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '5px 0px 0px 0px',
                textAlign: 'start'
              }}
            >
              {!infoUpdate && (
                <b>
                  {el.label}{' '}
                  {el?.descriptionLabel && (
                    <span style={{ fontSize: 13, fontWeight: 500 }}>
                      &nbsp;· {el.descriptionLabel}
                    </span>
                  )}
                </b>
              )}
              {el?.descriptionTitle ? (
                <>
                  <Typography
                    style={{
                      fontSize: 16,
                      margin: '5px 0px',
                      ...el.styleDescriptionTitle
                    }}
                  >
                    {el.descriptionTitle}
                  </Typography>
                </>
              ) : null}
            </div>
            <FormControl style={{ width: '100%' }} variant="standard">
              <Stack direction="row" alignItems="center">
                <BootstrapInput
                  disabled={el.disabled || false}
                  fullWidth
                  placeholder={el.placeholder}
                  multiline
                  size="small"
                  inputProps={{ maxLength: el?.maxLength }}
                  rows={el.maxRows ? undefined : el?.rows ? el.rows : 1}
                  maxRows={el.maxRows ?? undefined}
                  name={el.name}
                  id={el.name}
                  inputRef={textInput}
                  defaultValue={
                    !el.buttonAdd
                      ? el.defaultValue
                        ? el.defaultValue
                        : formik.values[el.name]
                      : ''
                  }
                  onChange={(event: any) => {
                    if (el.onChange) {
                      if (!el.buttonAdd) {
                        debounceTextfield(el.name, event.target.value);

                        if (formik.values[el.name] !== event.target.value) {
                          setLoading && setLoading(el.name);
                        }
                      }
                      if (el.buttonAdd && event.target.value) {
                        let tempStringCurrent: any = tempString.find(
                          (item: any) => item.key === el.name
                        )
                          ? tempString.map((item: any) =>
                              item.key === el.name
                                ? {
                                    key: el.name,
                                    value: event.target.value
                                  }
                                : item
                            )
                          : tempString.concat([
                              { key: el.name, value: event.target.value }
                            ]);
                        setTempString(tempStringCurrent);
                      }
                    }
                    setVerify && setVerify(false);
                  }}
                  onBlur={(event: any) => {
                    formik.handleBlur(event);
                    if (!el.buttonAdd) {
                      formik.setFieldValue(el.name, event.target.value);
                      if (formik.values[el.name] !== event.target.value) {
                        setLoading && setLoading(el.name);
                      }
                    }
                    if (el.buttonAdd && event.target.value) {
                      let tempStringCurrent: any = tempString.find(
                        (item: any) => item.key === el.name
                      )
                        ? tempString.map((item: any) =>
                            item.key === el.name
                              ? {
                                  key: el.name,
                                  value: event.target.value
                                }
                              : item
                          )
                        : tempString.concat([
                            { key: el.name, value: event.target.value }
                          ]);
                      setTempString(tempStringCurrent);
                    }
                    setActionUpdate && setActionUpdate(true);
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      border:
                        el.errorText ||
                        (formik.touched[el.name] && formik.errors[el.name])
                          ? '1px solid #f00'
                          : '1px solid #ced4da',
                      cursor: el.disabled ? 'no-drop' : 'auto',
                      padding: el?.size === 'small' ? '4px 12px' : '16px 12px'
                    }
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ position: 'absolute', right: '5px' }}
                    >
                      {(el.errorText ||
                        (formik.touched[el.name] &&
                          formik.errors[el.name])) && (
                        <i
                          className="fa-solid fa-triangle-exclamation"
                          style={{
                            marginLeft: '-36px',
                            fontSize: 20,
                            color: '#f00'
                          }}
                        ></i>
                      )}
                      {formik.touched[el.name] &&
                        !formik.errors[el.name] &&
                        formik.values[el.name]?.length > 0 &&
                        iconCheckPage && (
                          <i
                            className="fa-solid fa-circle-check"
                            style={{
                              marginLeft: '-36px',
                              fontSize: 20,
                              color: '#4ce600'
                            }}
                          ></i>
                        )}
                      {el.buttonAdd && (
                        <ButtonCustom
                          label={el.buttonAdd.label}
                          style={{
                            marginLeft: '10px',
                            height: 46,
                            ...el.buttonAdd.style
                          }}
                          action={() => {
                            if (tempString.length) {
                              let currentField: any = formik.values[
                                el.name
                              ].concat([
                                tempString.find(
                                  (item: any) => item.key === el.name
                                )?.value
                              ]);
                              formik.setFieldValue(
                                el.name,
                                Array.from(
                                  new Set(
                                    currentField.filter(
                                      (item: any) => item && item !== ''
                                    )
                                  )
                                )
                              );
                              setTempString([{ key: '', value: '' }]);
                              textInput.current.value = '';
                            }
                          }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Stack>
              {el.description && (
                <div style={{ width: '100%' }}>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12, width: '100%' }}
                  >
                    {el.description}
                  </Typography>
                </div>
              )}
              {formik.touched[el.name] && formik.errors[el.name] ? (
                <FormHelperText
                  id="component-error-text"
                  sx={{ color: '#f00' }}
                >
                  {formik.errors[el.name]}
                </FormHelperText>
              ) : null}
              <MyFormHelperText helpText={el.errorText ? '' : el.helpText} />
              {el.errorText && (
                <Typography
                  variant="caption"
                  sx={{ color: '#f00', fontSize: '12' }}
                >
                  {el.errorText}
                </Typography>
              )}
              {el?.multipleAdd && formik.values[el.name] !== null && (
                <Grid
                  container
                  item
                  xs={12}
                  xl={12}
                  spacing={1}
                  justifyContent="space-between"
                  style={{ width: '100%', margin: '5px 0px' }}
                >
                  <Grid
                    item
                    xs={12}
                    xl={8}
                    sx={{
                      display: 'block',
                      width: '100%',
                      border: '1px solid #ced4da',
                      borderRadius: '12px',
                      padding: '5px',
                      minHeight: '58px'
                    }}
                  >
                    {formik.values[el.name]?.map((tag: any, index) => (
                      <Chip
                        key={tag}
                        variant="outlined"
                        color={'default'}
                        label={tag}
                        onDelete={() => {
                          let newValue: any = formik.values[el.name]?.filter(
                            (item: any) => item !== tag
                          );
                          formik.setFieldValue(el.name, newValue);
                        }}
                      />
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    xl={4}
                    sx={{ display: 'flex', justifyContent: 'end' }}
                  >
                    {el?.buttonSave && (
                      <ButtonInherit
                        label={el.buttonSave.label}
                        style={{
                          marginLeft: '10px',
                          height: 36,
                          ...el.buttonSave.style
                        }}
                        action={el.buttonSave.action}
                        loading={
                          loading && el.buttonSave.type === buttonSeleted
                            ? true
                            : false
                        }
                        disabled={
                          el?.buttonSave?.dataDefault?.length ? false : true
                        }
                      />
                    )}
                    {el?.buttonDeleteAll && (
                      <ButtonInherit
                        label={el.buttonDeleteAll.label}
                        style={{
                          marginLeft: '10px',
                          height: 36,
                          backgroundColor: '#eceff6',
                          ...el.buttonDeleteAll.style
                        }}
                        action={el.buttonDeleteAll.action}
                        loading={
                          loading && el.buttonDeleteAll.type === buttonSeleted
                            ? true
                            : false
                        }
                        disabled={
                          el?.buttonDeleteAll?.dataDefault?.length
                            ? false
                            : true
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </FormControl>
          </Grid>
        );
      case 'autocomplete':
        return (
          <Grid key={index} item xs={el?.span ? el.span : 12}>
            <div
              style={{
                display: 'inline-block',
                width: '100%',
                textAlign: 'start'
              }}
            >
              {!infoUpdate && (
                <b
                  style={{
                    marginBottom: '5px',
                    marginTop: '5px',
                    ...el.styleLabel
                  }}
                >
                  {el.label}
                  {el?.descriptionLabel && (
                    <span style={{ fontSize: 13, fontWeight: 500 }}>
                      &nbsp;· {el.descriptionLabel}
                    </span>
                  )}
                </b>
              )}
              {el?.descriptionTitle ? (
                <span style={{ color: '#a7a7a7', fontSize: 16, margin: '5px' }}>
                  {el.descriptionTitle}
                </span>
              ) : null}
            </div>
            <div
              onClick={() => {
                if (el.action) {
                  el.action();
                }
              }}
            >
              <Autocomplete
                fullWidth
                size={size}
                disableClearable
                multiple={el?.multiple || false}
                noOptionsText={
                  el?.loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Không có dữ liệu...'
                  )
                }
                disabled={el?.disabled}
                value={formik.values[el.name] ? formik.values[el.name] : null}
                options={el.options || ['']}
                getOptionLabel={optionId => {
                  const { options } = el;
                  const option = options?.find(
                    option => option?.id === optionId.id
                  );
                  return (
                    option?.text ||
                    option?.name ||
                    option?.display_name ||
                    option?.title ||
                    option?.province_name ||
                    option?.district_name ||
                    option?.ward_name ||
                    ''
                  );
                }}
                renderOption={(props: any, option: any) => {
                  if (el?.multiple) {
                    let optionSelected: any = formik.values[el.name]?.find(
                      (el: any) => el.id === option.id
                    );
                    return (
                      !optionSelected && (
                        <Box {...props}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {option?.avatar ? (
                              <img
                                loading="lazy"
                                width="36"
                                style={{
                                  borderRadius: '50%',
                                  margin: '3px 8px 3px 0px'
                                }}
                                src={option?.avatar}
                                alt=""
                              />
                            ) : null}
                            {option.text || option.name || option.display_name}
                          </Box>
                        </Box>
                      )
                    );
                  } else {
                    return (
                      formik.values[el.name] !== option.id &&
                      (option.openOptionsDialog ? (
                        <Button
                          {...props}
                          sx={{
                            width: '100%',
                            textTransform: 'none',
                            color: '#000'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {option?.icon && el?.typeCategory !== 'category' ? (
                              <i
                                className={option.icon}
                                style={{
                                  fontSize: '20px',
                                  backgroundColor: '#e4e6eb',
                                  marginRight: '8px',
                                  borderRadius: '50%',
                                  padding: '8px',
                                  height: 38,
                                  width: 38,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              ></i>
                            ) : null}

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start'
                              }}
                            >
                              <Typography>
                                {option.text ||
                                  option.name ||
                                  option.display_name}
                              </Typography>
                              {option?.description ? (
                                <Typography sx={{ fontSize: 12 }}>
                                  {option.description}
                                </Typography>
                              ) : null}
                            </div>
                          </Box>
                        </Button>
                      ) : (
                        <Box {...props}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {option?.icon && el?.typeCategory !== 'category' ? (
                              <i
                                className={option.icon}
                                style={{
                                  color:
                                    theme.palette.mode === 'dark'
                                      ? '#fff'
                                      : '#000',
                                  fontSize: '20px',
                                  backgroundColor:
                                    theme.palette.mode === 'dark'
                                      ? '#3a3b3c'
                                      : '#e4e6eb',
                                  marginRight: '8px',
                                  borderRadius: '50%',
                                  padding: '8px',
                                  height: 38,
                                  width: 38,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              ></i>
                            ) : null}

                            {el.name === 'category_id' && option?.icon && (
                              <img
                                loading="lazy"
                                width="36"
                                style={{
                                  borderRadius: '50%',
                                  margin: '3px 8px 3px 0px'
                                }}
                                src={option?.icon}
                                alt=""
                              />
                            )}
                            {option?.avatar ? (
                              <img
                                loading="lazy"
                                width="36"
                                style={{
                                  borderRadius: '50%',
                                  margin: '3px 8px 3px 0px'
                                }}
                                src={option?.avatar}
                                alt=""
                              />
                            ) : null}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Typography sx={{ fontWeight: 500 }}>
                                {option.text ||
                                  option.name ||
                                  option.display_name ||
                                  option.title ||
                                  option.province_name ||
                                  option.district_name ||
                                  option.ward_name}
                              </Typography>
                            </div>
                          </Box>
                        </Box>
                      ))
                    );
                  }
                }}
                filterSelectedOptions
                forcePopupIcon={false}
                limitTags={el?.limitsTag ? el?.limitsTag : -1}
                renderInput={params => {
                  return (
                    <RedditTextField
                      {...params}
                      name={el.name}
                      label={
                        (
                          <Typography
                            style={{
                              color: '#c8c8c8',
                              marginLeft: formik.values[el.name]?.icon
                                ? '52px'
                                : '0px'
                            }}
                          >
                            {el.placeholder}
                          </Typography>
                        ) || ''
                      }
                      variant="filled"
                      sx={{
                        marginTop: '4px',
                        width: '100%',
                        fontWeight: '700',
                        ...el.style
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        endAdornment: el.endButton ? (
                          <IconButtonOptions
                            icon={
                              <i
                                style={{ fontSize: 14, fontWeight: 500 }}
                                className={el.endButton?.icon}
                                aria-hidden="true"
                              ></i>
                            }
                            style={{
                              margin: '-18px 0px 0px 4px'
                            }}
                            action={() => {
                              return el.endButton.action;
                            }}
                          />
                        ) : (
                          params.InputProps.endAdornment
                        ),
                        startAdornment:
                          !el.multiple && formik.values[el.name]?.icon ? (
                            el.name === 'category_id' ? (
                              <img
                                src={formik.values[el.name]?.icon}
                                style={{
                                  fontSize: '20px',
                                  margin: '-18px 8px 0px 8px',
                                  borderRadius: '50%',
                                  color: '#6f6f6f',
                                  height: 24,
                                  width: 24,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                                alt=""
                              />
                            ) : (
                              <i
                                className={formik.values[el.name]?.icon}
                                style={{
                                  fontSize: '20px',
                                  margin: '-18px 8px 0px 8px',
                                  borderRadius: '50%',
                                  color: '#6f6f6f',
                                  height: 24,
                                  width: 24,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              ></i>
                            )
                          ) : (
                            params.InputProps.startAdornment
                          )
                      }}
                      onChange={event => {
                        if (event.target.value && el?.search && el?.fetchData) {
                          el.fetchData(event.target.value);
                          el.setLoading(true);
                        }
                        if (event.target.value && el?.searchParams) {
                          handleSearchParams(el, event.target.value);
                        }
                        if (
                          !event.target.value &&
                          formik.values[el.name]?.length === 0 &&
                          el.search
                        ) {
                          el?.setOptions && el?.setOptions([]);
                        }
                      }}
                      error={
                        formik.touched[el.name] &&
                        Boolean(formik.errors[el.name])
                      }
                      helperText={
                        formik.touched[el.name] && formik.errors[el.name]
                      }
                    />
                  );
                }}
                onChange={(event, value: any) => {
                  setNotificationLeave && setNotificationLeave(true);
                  setActionUpdate && setActionUpdate(true);
                  if (value.length === 0 && el.search) {
                    el?.setOptions && el?.setOptions([]);
                  }

                  if (formik.values.district && el.name === 'province') {
                    formik.setFieldValue('district', null);
                    formik.setFieldValue('ward', null);
                  }

                  if (
                    (!value.openOptionsDialog &&
                      el.limit &&
                      value?.length < el?.limit) ||
                    (!value.openOptionsDialog && !el.limit)
                  ) {
                    formik.setFieldValue(el.name, value);
                  }
                }}
                getOptionDisabled={(option: any) =>
                  el?.limit ? formik.values[el.name].length === el.limit : false
                }
              />
            </div>

            {el.helpText && (
              <div style={{ width: '100%' }}>
                <Typography
                  variant="caption"
                  sx={{ fontSize: 12, width: '100%' }}
                >
                  {el.helpText}
                </Typography>
              </div>
            )}
            {el.renderListTag &&
            formik.values[el.name]?.length &&
            !el?.distributeStatus ? (
              <div style={{ width: '100%', margin: '15px 0px' }}>
                {formik.values[el.name]?.map((item: any) => (
                  <IconButtonOptions
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '42px',
                      margin: '12px 0px'
                    }}
                    avatar={item?.avatar}
                    styleNameButton={{
                      color: 'text.primary'
                    }}
                    styleAvatar={{ height: 36, width: 36 }}
                    name={item?.name || item?.text || item?.display_name}
                    description={item?.description}
                    deleteIconButton={true}
                    projectAddHostStatus={el.status_item ? '' : item?.status}
                    actionDeleteIconButton={() => {
                      if (
                        currentHostAdd?.some(
                          itemCurrent => itemCurrent?.id === item?.id
                        ) //check to see if the host being deleted is in the list host from the database?
                      ) {
                        formik.setFieldValue('event_host_removed', [
                          ...formik.values['event_host_removed'],
                          item
                        ]);
                      } else if (
                        currenHostAddProject?.some(
                          itemCurrent => itemCurrent?.id === item?.id
                        )
                      ) {
                        formik.setFieldValue('project_host_removed', [
                          ...formik.values['project_host_removed'],
                          item
                        ]);
                      }

                      return formik.setFieldValue(
                        el.name,
                        formik.values[el.name].filter(
                          (e: any) => e.id !== item.id
                        )
                      );
                    }}
                  />
                ))}
              </div>
            ) : null}
            {el.renderListTag &&
            formik.values[el.name]?.length &&
            el?.distributeStatus ? (
              <>
                <div>
                  {distributeStatusHost(el, 'approved').length > 0 && (
                    <Typography>Đã chấp nhận</Typography>
                  )}
                  {distributeStatusHost(el, 'approved').length > 0 &&
                    distributeStatusHost(el, 'approved')?.map((item: any) => (
                      <IconButtonOptions
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: '42px',
                          margin: '12px 0px',
                          backgroundColor: '#fff'
                        }}
                        avatar={item?.avatar}
                        styleNameButton={{
                          color: 'text.primary'
                        }}
                        styleAvatar={{ height: 36, width: 36 }}
                        name={item?.name || item?.text || item?.display_name}
                        deleteIconButton={true}
                        projectAddHostStatus={item?.status}
                        actionDeleteIconButton={() => {
                          if (
                            currentHostAdd?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            ) //check to see if the host being deleted is in the list host from the database?
                          ) {
                            formik.setFieldValue('event_host_removed', [
                              ...formik.values['event_host_removed'],
                              item
                            ]);
                          } else if (
                            currenHostAddProject?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            )
                          ) {
                            formik.setFieldValue('project_host_removed', [
                              ...formik.values['project_host_removed'],
                              item
                            ]);
                          }
                          return formik.setFieldValue(
                            el.name,
                            formik.values[el.name].filter(
                              (e: any) => e.id !== item.id
                            )
                          );
                        }}
                      />
                    ))}
                </div>
                <div>
                  {distributeStatusHost(el, 'pending').length > 0 && (
                    <Typography>Đang chờ</Typography>
                  )}
                  {distributeStatusHost(el, 'pending').length > 0 &&
                    distributeStatusHost(el, 'pending')?.map((item: any) => (
                      <IconButtonOptions
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: '42px',
                          margin: '12px 0px',
                          backgroundColor: '#fff'
                        }}
                        avatar={item?.avatar}
                        styleNameButton={{
                          color: 'text.primary'
                        }}
                        styleAvatar={{ height: 36, width: 36 }}
                        name={item?.name || item?.text || item?.display_name}
                        deleteIconButton={true}
                        projectAddHostStatus={item?.status}
                        actionDeleteIconButton={() => {
                          if (
                            currentHostAdd?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            ) //check to see if the host being deleted is in the list host from the database?
                          ) {
                            formik.setFieldValue('event_host_removed', [
                              ...formik.values['event_host_removed'],
                              item
                            ]);
                          } else if (
                            currenHostAddProject?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            )
                          ) {
                            formik.setFieldValue('project_host_removed', [
                              ...formik.values['project_host_removed'],
                              item
                            ]);
                          }
                          return formik.setFieldValue(
                            el.name,
                            formik.values[el.name].filter(
                              (e: any) => e.id !== item.id
                            )
                          );
                        }}
                      />
                    ))}
                </div>
                <div>
                  {distributeStatusHost(el, 'rejected').length > 0 && (
                    <Typography>Đã từ chối</Typography>
                  )}
                  {distributeStatusHost(el, 'rejected').length > 0 &&
                    distributeStatusHost(el, 'rejected')?.map((item: any) => (
                      <IconButtonOptions
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: '42px',
                          margin: '12px 0px',
                          backgroundColor: '#fff'
                        }}
                        avatar={item?.avatar}
                        styleNameButton={{
                          color: 'text.primary'
                        }}
                        styleAvatar={{ height: 36, width: 36 }}
                        name={item?.name || item?.text || item?.display_name}
                        deleteIconButton={true}
                        projectAddHostStatus={item?.status}
                        actionDeleteIconButton={() => {
                          if (
                            currentHostAdd?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            ) //check to see if the host being deleted is in the list host from the database?
                          ) {
                            formik.setFieldValue('event_host_removed', [
                              ...formik.values['event_host_removed'],
                              item
                            ]);
                          } else if (
                            currenHostAddProject?.some(
                              itemCurrent => itemCurrent?.id === item?.id
                            )
                          ) {
                            formik.setFieldValue('project_host_removed', [
                              ...formik.values['project_host_removed'],
                              item
                            ]);
                          }
                          return formik.setFieldValue(
                            el.name,
                            formik.values[el.name].filter(
                              (e: any) => e.id !== item.id
                            )
                          );
                        }}
                      />
                    ))}
                </div>
              </>
            ) : null}
          </Grid>
        );
      case 'autocomplete_solo':
        return (
          <Autocomplete
            key={index}
            value={formik.values[el.name] ? formik.values[el.name] : ''}
            options={el.options || ['']}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            filterOptions={x => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText={
              el?.loading ? (
                <CircularProgress size={20} />
              ) : (
                'Không có dữ liệu...'
              )
            }
            onChange={(event, value) => {
              if (typeof value === 'string') {
                formik.setFieldValue(el.name, value);
              } else if (value && value.inputValue) {
                formik.setFieldValue(el.name, value);
              } else {
                formik.setFieldValue(el.name, value);
              }
              el?.setList(value ? [value, ...el?.options] : el?.options);
            }}
            onInputChange={(event, value: any) => {
              handleInputChange(value, el);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option) => (
              <li {...props}>
                <Grid className={classes.itemFlex}>
                  <img
                    src={option.avatar_media.preview_url}
                    className={classes.listAvatar}
                    alt="avatar"
                  />
                  <Typography>{option.title}</Typography>
                </Grid>
              </li>
            )}
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label={el.placeholder}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    paddingRight: '80px'
                  }
                }}
              />
            )}
          />
        );
      case 'checkbox':
        return (
          <Grid key={index} item xs={el?.span ? el.span : 12}>
            <b>{!infoUpdate && el.label}</b>
            {el.options &&
              el.options.map((item: any, index) => (
                <React.Fragment key={item.label}>
                  <Typography variant="body2">{item.label}</Typography>
                  <FormGroup>
                    {item.typeCheckbox === 'truefalse' ? (
                      <>
                        <FormControlLabel
                          control={<Checkbox />}
                          label={'Có'}
                          sx={{ margin: '2px' }}
                        />
                        <FormControlLabel
                          control={<Checkbox sx={{ padding: '2px' }} />}
                          label={'Không'}
                        />
                      </>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values[item.name]}
                            onChange={e =>
                              formik.setFieldValue(item.name, e.target.checked)
                            }
                          />
                        }
                        label={item.labelRadio}
                      />
                    )}
                  </FormGroup>
                </React.Fragment>
              ))}
          </Grid>
        );
      case 'radio':
        return (
          <Grid
            key={index}
            item
            xs={el?.span ? el.span : 12}
            sx={{ display: 'flex', flexDirection: 'column', ...el.style }}
          >
            {el.openRadio ? (
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0px'
                }}
                disableRipple
                disableFocusRipple
                disableElevation
                onClick={() =>
                  el.openField !== 'place_expand'
                    ? el.setOpenField('place_expand')
                    : el.setOpenField('')
                }
              >
                {el.label}
                {el.openField === 'place_expand' ? (
                  <i
                    style={{ marginRight: '15px' }}
                    className="fa-solid fa-angle-down"
                  ></i>
                ) : (
                  <i
                    style={{ marginRight: '15px' }}
                    className="fa-solid fa-angle-up"
                  ></i>
                )}
              </Button>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0px'
                }}
              >
                {el.label}
              </Typography>
            )}
            <FormControl sx={{ ...el.styleFormControl }}>
              {el.type === 'groupRadio' ? (
                el.openField === 'place_expand' ? (
                  <Box
                    style={{
                      padding: '8px 15px',
                      borderRadius: '8px'
                    }}
                  >
                    {el.options &&
                      el.options.map((el: any, index) => (
                        <RadioGroup
                          key={index}
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <Typography>{el.labelRadio}</Typography>
                          <FormControlLabel
                            value={el.valueTrue}
                            control={<Radio />}
                            label="Có"
                          />
                          <FormControlLabel
                            value={el.valueFalse}
                            control={<Radio />}
                            label="Không"
                          />
                        </RadioGroup>
                      ))}
                  </Box>
                ) : null
              ) : (
                el.options && (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={formik.values[el.name]}
                    name="radio-buttons-group"
                    defaultValue={el?.options[0]?.value}
                  >
                    {el.options.map((item: any, index) => (
                      <FormControlLabel
                        key={item}
                        value={item.value}
                        control={
                          <Radio
                            value={item.value}
                            sx={{
                              '&.Mui-checked': {
                                color: buttonColor.backgroundColor
                              }
                            }}
                            onClick={() => {
                              formik.setFieldValue(el.name, item.value);
                              setActionUpdate && setActionUpdate(true);
                            }}
                          />
                        }
                        label={
                          <div>
                            <Typography>{item.labelRadio}</Typography>
                            <FormHelperText sx={{ fontSize: 11, margin: 0 }}>
                              {item.helpText}
                            </FormHelperText>
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                )
              )}
            </FormControl>
          </Grid>
        );

      case 'button_add':
        return (
          <Typography
            key={index}
            sx={{
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              margin: '8px 0px',
              cursor: 'pointer',
              fontSize: '0.875rem'
            }}
            onClick={() => {
              el.action();
            }}
          >
            {el.icon}
            {el.label}
          </Typography>
        );
      case 'upload_media':
        let textError = '';
        if (errorMp3.length && el.type === 'file_mp3') {
          textError = errorMp3;
        } else if (errorImage.length && el.name === 'banner') {
          textError = errorImage;
        } else if (formik.errors[el.name]) {
          textError = formik.errors[el.name];
        } else {
          textError = '';
        }
        return (
          <Grid
            key={index}
            item
            xs={el?.span ? el.span : 12}
            sx={{ padding: '5px 0px', position: 'relative' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '5px 0px 5px 0px',
                textAlign: 'start'
              }}
            >
              {!infoUpdate && (
                <b>
                  {el.label}
                  {el?.descriptionLabel && (
                    <span style={{ fontSize: 13, fontWeight: 500 }}>
                      &nbsp;· {el.descriptionLabel}
                    </span>
                  )}
                </b>
              )}
              {el?.descriptionTitle ? (
                <span
                  style={{
                    color: '#a7a7a7',
                    fontSize: 16,
                    margin: '5px 0px'
                  }}
                >
                  {el.descriptionTitle}
                </span>
              ) : null}
            </div>
            <ImageUploadProfile
              setType={setType}
              filedName={el.name}
              coverImage={true}
              setLoadingImageUpload={setLoadingImageUpload}
              setErrorImage={setErrorImage}
              setErrorMp3={setErrorMp3}
              setErrorItem={setErrorItem}
              action={file => {
                if (el?.type_field === 'binary') {
                  formik.setFieldValue(el.name, file);
                } else {
                  formik.setFieldValue(
                    el.name,
                    file
                      ? {
                          id: (infoUpdate?.length && file?.id) ?? null,
                          file: file,
                          show_url: file?.show_url
                        }
                      : null
                  );
                }
              }}
              type={el.type}
              type_video={el.type_video}
              typeVideo={el.typeCourse}
              height={el.height}
              fileUpload={el.default_media}
              label={el.label}
            />
            {(errorImage || errorMp3 || formik.errors[el.name]) &&
            textError.length ? (
              <Typography
                id="error_image"
                sx={{ color: '#F0284A', fontSize: '12px' }}
              >
                {textError}
              </Typography>
            ) : null}

            {loadingImageUpload && types === el.name && (
              <Box
                sx={{
                  width: '100%',

                  height: el?.height ? el?.height : '160px',
                  position: 'absolute',
                  left: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  alignItems: 'center',
                  bottom: el.type === 'file' ? 50 : 5
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Grid>
        );
      case 'typography':
        return (
          <div
            style={{ ...el.styleDiv, textAlign: 'start', width: '100%' }}
            key={index}
          >
            <span style={{ fontSize: 16, ...el.style }}>{el.text}</span>
            {el?.description && (
              <span style={{ fontSize: 13, ...el.styleDescription }}>
                &nbsp;· {el.description}
              </span>
            )}
            {el.maxTimeNoti && (
              <p style={{ fontSize: 12 }}>{`(${el.maxTimeNoti})`}</p>
            )}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Grid
      container
      spacing={spacing !== undefined ? spacing : 2}
      sx={{ ...styleContainer, width: '100%' }}
    >
      {infoUpdate?.length
        ? fieldUpdate
            ?.filter((el: any) => infoUpdate.includes(el.name))
            ?.map((el: any, index) => renderField(el, index))
        : fieldUpdate?.map((el: any, index) => renderField(el, index))}
    </Grid>
  );
}

export default SocialFieldUpdate;
