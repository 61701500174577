import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeCookie } from 'src/common/token';
import { PATHS } from 'src/constants/paths';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    right: 20,
    top: 50,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2) !important',
    borderRadius: 10,
    padding: 12,
    backgroundColor: '#fff'
  },
  wrapUserBlock: {
    margin: '8px 4px 12px 4px',
    padding: '4px',
    borderRadius: 10,
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.2)'
  },
  boxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0px'
  },
  rootListButton: {
    backgroundColor: 'transparent',
    marginLeft: 10
  }
}));
const DropdownArrow = props => {
  const { setOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  function handleLogOut() {
    localStorage.removeItem('isFastLogin');
    localStorage.removeItem('userId');
    removeCookie();
    history.push(`${PATHS.LOGIN}`);
  }
  const listMenu = [
    {
      id: 'logout',
      label: 'Đăng xuất',
      icon: 'fas fa-sign-out-alt',
      action: handleLogOut,
      type: 'menu'
    }
  ];
  const renderListAction = (item, type) => {
    return (
      <ListItem
        key={item.id}
        style={{ borderRadius: 10, padding: '4px 0px' }}
        button
        dense={true}
      >
        <ListItemButton
          onClick={() => {
            item.action && item.action();
            setOpen(false);
          }}
          classes={{ root: classes.rootListButton }}
          dense
        >
          <ListItemIcon>
            <IconButton
              style={{
                width: 36,
                height: 36
              }}
            >
              <i className={item.icon}></i>
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                {type === 'account' ? item.name : item.label}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.boxItem}>
        <Avatar
          sx={{ width: 40, height: 40, cursor: 'pointer' }}
          src={meInfo?.avatar_media?.show_url}
          alt="avatar_user"
        />
        <Typography>{meInfo?.display_name}</Typography>
      </Box>
      <Divider />
      {listMenu?.map((el: any) => renderListAction(el, el.type))}
    </Box>
  );
};

export default DropdownArrow;
