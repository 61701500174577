import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import group_cover from 'src/assets/images/group_cover.png';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { uploadMediasSaga } from 'src/store/saga/socialPostSaga';
import WatchPlayer from '../VideoPlayer/WatchPlayer';
function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={35}
        thickness={5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ImageUploadProfile = props => {
  const {
    type,
    action,
    fileUpload,
    height,
    label,
    typeVideo,
    setCheckCreatePage,
    match,
    filedName,
    setType,
    setOpenSnackbar,
    setNoti,
    type_video,
    setLoadingImageUpload,
    setErrorImage,
    setErrorItem,
    setErrorMp3,
    coverImage
  } = props;
  const useStyles = makeStyles((theme: any) => ({
    wrapHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& .reactEasyCrop_Container': {
        height: height ? `${height}px !important` : '250px !important',
        overflow: 'hidden',
        borderRadius: '10px'
      },
      '& .reactEasyCrop_CropAreaGrid': {
        height: height ? `${height}px !important` : '250px !important',
        width: coverImage ? '100% !important' : null,
        '&::before': {
          border: '0px !important'
        },
        '&::after': {
          border: '0px !important',
          width: '100% !important'
        }
      },
      '& .reactEasyCrop_CropArea': {
        color: 'rgba(255,255,255,0.7) !important'
      }
    },
    wrapUpload: {
      width: '100%',
      borderRadius: 10,
      height: '160px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2a2c2f',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#e1e5eb'
      }
    },
    text: {
      fontSize: '15px !important',
      fontWeight: '600 !important',
      padding: '0px 12px'
    },
    wrapRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    wrapText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }));

  const classes = useStyles();
  const [files, setFiles] = React.useState<any>([]);
  const imageUpload = useSelector((state: any) => state?.settingReducer);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>('');
  const [progress, setProgress] = React.useState<any>(0);
  let typeAccept;

  if (type === 'file') {
    typeAccept = 'video/mp4';
  } else if (type === 'file_mp3') {
    typeAccept = '.ogg, .oga, .mp3, .wav, .flac, .opus, .aac, .m4a, .3gp, .wma';
  } else {
    typeAccept = 'image/jpeg,image/png,image/jpg';
  }

  const onDropRejected = fileRejections => {
    if (type === 'file_mp3') {
      // Xử lý khi tệp bị từ chối (vượt quá kích thước)
      const rejectedFile = fileRejections[0].file;
      const errorMessage = `Tệp ${rejectedFile.name} vượt quá kích thước tối đa (60MB)`;
      setErrorMp3(errorMessage);
    }
  };
  const maxSize = type === 'file_mp3' ? 60 * 1024 * 1024 : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    accept: typeAccept as unknown as DropzoneOptions['accept'],
    onDrop: async (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      setCheckCreatePage && setCheckCreatePage(false);
    },
    onDropRejected: onDropRejected,
    maxSize: maxSize
  });

  const getMediaFile = async () => {
    try {
      if (
        type === 'file' ||
        type === 'file_document' ||
        type === 'file_media' ||
        type === 'file_mp3'
      ) {
        setLoadingImageUpload(true);
        let response = await uploadMediasSaga(files, setProgress, 'others');
        if (response.length > 0 && response[0].id) {
          setLoadingImageUpload(false);
        }
        if (
          action &&
          files?.length &&
          files[0]?.type === 'video/mp4' &&
          type === 'file'
        ) {
          let data = {
            id: files[0]?.id ?? response[0]?.id,
            show_url: files[0]?.show_url
              ? files[0]?.show_url
              : files[0]?.preview,
            file: response[0]
          };
          action(data);
        } else if (
          action &&
          files?.length &&
          (type === 'file_document' ||
            type === 'file_media' ||
            type === 'file_mp3')
        ) {
          let data = {
            id: files[0]?.id ?? response[0]?.id,
            show_url: files[0]?.show_url,
            file: files[0]
          };
          action(data);
        }
      }
    } catch (error) {
      setLoadingImageUpload(false);

      setOpenSnackbar && setOpenSnackbar(true);
      setNoti &&
        setNoti({
          code: 400,
          message: 'Video tải lên bị lỗi.Vui lòng thử lại!'
        });
    }
  };

  React.useEffect(() => {
    if (files?.length && !files[0]?.file?.id) {
      getMediaFile();
    }
  }, [JSON.stringify(files)]);

  const showImage = async () => {
    let newFile = files[0];
    Object.assign(newFile, {
      show_url: files[0]?.preview
    });
    action && action({ file: newFile });
  };

  React.useEffect(() => {
    if (files?.length) {
      showImage();
    }
  }, [JSON.stringify(croppedAreaPixels)]);

  React.useEffect(() => {
    if (
      imageUpload?.id &&
      action &&
      files?.length &&
      type !== 'file' &&
      type !== 'file_document' &&
      type !== 'file_media'
    ) {
      let data = {
        id: imageUpload?.id,
        show_url: files[0]?.show_url,
        file: files[0]
      };
      action(data);
    } else if (
      action &&
      files?.length &&
      type === 'file' &&
      type_video !== 'file_video_intro' &&
      typeVideo !== 'file_course' &&
      files[0]?.type !== 'video/mp4'
    ) {
      let data = {
        id: files[0]?.id ?? null,
        show_url: files[0]?.show_url,
        file: files[0]
      };
      action(data);
    }
  }, [JSON.stringify(imageUpload), type, files?.length]);

  React.useEffect(() => {
    if (fileUpload && Object.keys(fileUpload).length && !files?.length) {
      setFiles([
        {
          ...fileUpload,
          preview: fileUpload.show_url || fileUpload.url
        }
      ]);
    }
  }, [JSON.stringify(fileUpload)]);

  const renderContentFile = () => {
    if (files.length) {
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <div className={classes.wrapHeader}>
            {files[0]?.type?.includes('video') ||
            typeVideo ||
            files[0]?.file?.file ? (
              files[0]?.type?.includes('/mp4') || files[0]?.file?.file ? (
                <video
                  style={{
                    backgroundColor: '#000',
                    borderRadius: '10px'
                  }}
                  width="100%"
                  height={height ? height : '190px'}
                  src={files[0]?.preview}
                  controls
                />
              ) : (
                <WatchPlayer type="video" video={files[0]} />
              )
            ) : (
              <img
                src={files[0]?.preview}
                alt="preview"
                width="100%"
                height="100%"
              />
            )}
          </div>
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    }
  };
  const renderContent = () => {
    if (files.length) {
      return (
        <div style={{ position: 'relative' }}>
          {type === 'file_media' ? (
            <img
              src={
                files[0]?.file?.file?.preview
                  ? files[0]?.file?.file?.preview
                  : files[0]?.preview
                  ? files[0]?.preview
                  : group_cover
              }
              alt="preview"
              width="100%"
              height={height ? height : '160px'}
              style={{ borderRadius: '10px', objectFit: 'cover' }}
            />
          ) : (
            <audio controls style={{ marginTop: '10px' }}>
              <source
                src={
                  files[0]?.preview
                    ? files[0]?.preview
                    : files[0]?.file?.file?.preview
                }
              />
            </audio>
          )}
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div style={{ position: 'relative' }}>
          <img
            src={group_cover}
            style={{
              width: '100%',
              height: height ? height : 250,
              borderRadius: 10
            }}
            alt="preivew"
          />
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {type === 'file' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {files.length ? (
            <>
              {renderContentFile()}
              {files.length ? (
                <div
                  style={{
                    marginTop: 10
                  }}
                >
                  <ButtonInherit
                    action={() => {
                      setFiles([]);
                      action && action(null);
                      setLoadingImageUpload(false);
                      setErrorMp3 && setErrorMp3('');
                    }}
                    style={{
                      backgroundColor: '#7165e0',
                      color: '#fff'
                    }}
                    label="Hủy"
                  />
                </div>
              ) : null}
            </>
          ) : (
            <Box
              sx={{ width: '100%', height: '100%', position: 'relative' }}
              onClick={() => setType(filedName)}
            >
              <div
                {...getRootProps()}
                className={classes.wrapUpload}
                style={{ height: height ? height : '160px' }}
              >
                <input {...getInputProps()} name={filedName} />
                <div className={classes.wrapRoot}>
                  <Box className={classes.wrapText}>
                    <Typography className={classes.text}>
                      Tải lên {label}
                    </Typography>
                  </Box>
                </div>
              </div>
              {match?.url === '/courses/create/offline' ? (
                <span style={{ fontSize: 13 }}>
                  Video tải lên không được quá 3 phút!
                </span>
              ) : (
                ''
              )}
            </Box>
          )}
        </div>
      ) : (
        <div style={{ position: 'relative', height: height ? height : 250 }}>
          {renderContent()}
          {files.length ? (
            <div
              style={{
                position: 'absolute',
                bottom: 10,
                right: 160,
                zIndex: 1000
              }}
            >
              <ButtonInherit
                action={() => {
                  action(null);
                  setFiles([]);
                  setErrorImage('');
                  setErrorItem && setErrorItem(false);
                  setLoadingImageUpload(false);
                  setErrorMp3 && setErrorMp3('');
                }}
                label="Hủy"
              />
            </div>
          ) : null}
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              zIndex: 1000,
              width: 150
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} name={filedName ? filedName : type} />
            <div
              onClick={() => {
                setType(filedName);
                setErrorMp3 && setErrorMp3('');
              }}
            >
              <ButtonCustom
                icon={
                  type === 'file_document' ? (
                    <i className="fa-solid fa-file"></i>
                  ) : (
                    <i className="fa-solid fa-camera"></i>
                  )
                }
                style={{
                  backgroundColor: 'rgb(234, 234, 234)',
                  fontWeight: '500'
                }}
                label={
                  type === 'banner_page' || type === 'banner_group'
                    ? 'Thêm ảnh bìa'
                    : type === 'file_document' || type === 'file_mp3'
                    ? 'Thêm File'
                    : 'Thêm ảnh'
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUploadProfile;
