import React from 'react';
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import { CKEditor } from '@ckeditor/ckeditor5-react';

// const editorConfiguration = {
//   toolbar: [
//     'heading',
//     'bold',
//     'italic',
//     'underline',
//     'blockQuote',
//     'insertTable',
//     'link',
//     'bulletedList',
//     'numberedList',
//     'outDent',
//     'inDent'
//   ]
// };

function Test() {
  return <div>abc</div>;
}

export default Test;
