import React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import { getCookie } from 'src/common/token';

interface ReduxProps {
  isAuthenticated: boolean;
}
interface Props extends ReduxProps, RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

function AuthenticatedGuard(props: Props) {
  const { isAuthenticated, component: Component, ...rest } = props;
  const tokenUser = getCookie('token');
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !tokenUser) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.appReducer.isAuthenticated
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuard);
