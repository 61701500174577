import * as types from 'src/constants/store/login';
import * as actions from 'src/store/action/login';
import * as loginApi from 'src/apis/socialAuth.api';
import { takeEvery, put, call } from 'redux-saga/effects';
import { getInfoMe } from 'src/apis/user.api';
import { getCookie, setCookie } from 'src/common/token';

export const LoginSagas = function* (action) {
  try {
    let data = action.payload;
    let response: any;
    if (!data.token) {
      response = yield call(loginApi.socialLoginApi, data);
      if (response.status === 200) {
        const token = response.data.access_token;
        setCookie(token);
        if (localStorage.getItem('isFastLogin') === null)
          localStorage.setItem('isFastLogin', 'no');
      }
    } else {
      setCookie(data.token);
      window.location.href = '/';
    }

    if (response.status === 200) {
      const tokenUser = getCookie();
      let res = yield call(getInfoMe, tokenUser);
      if (res.status === 200) {
        if (res.data?.id && res.data.theme !== 'light') {
          localStorage.setItem('darkThemeUser', res.data?.theme);
        }
      }
      yield put(actions.loginSuccess());
      window.location.href = '/';
    }
  } catch (err) {
    yield put(
      actions.loginFailed({
        isError: true
      })
    );
  }
};

export function* watchLoginAsync() {
  yield takeEvery(types.LOGIN_REQ, LoginSagas);
}
