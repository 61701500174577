import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CustomizedMenus(props) {
  const {
    label,
    disabled,
    color,
    action,
    fullWidth,
    icon,
    endIcon,
    style,
    variant,
    textColor,
    loading,
    styleHover,
    solid,
    type = 'button'
  } = props;

  const theme = useTheme();

  return (
    <LoadingButton
      type={type}
      id="demo-customized-button"
      aria-haspopup="true"
      variant={variant ? variant : 'contained'}
      disableElevation
      loading={loading}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
      onClick={e => {
        action && action(e);
      }}
      style={style}
      startIcon={icon}
      endIcon={endIcon}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        textTransform: 'none',
        fontWeight: '600 !important',
        fontSize: 16,
        padding: '4px 12px',
        marginRight: '7px',
        backgroundColor: loading
          ? 'button.custom.background'
          : variant === 'text'
          ? 'transparent'
          : color && !disabled
          ? color
          : theme.palette.mode === 'dark'
          ? '#4b4b4b'
          : '#7165e0',
        color: textColor ? textColor : color ? '#f7f7f7' : '#fff',
        '&:hover': {
          backgroundColor: solid
            ? 'button.custom.hoverSolid'
            : 'button.custom.hover',
          ...styleHover
        },
        height: '36px',
        borderRadius: '6px',
        cursor: disabled ? 'no-drop' : 'pointer',
        ...style
      }}
    >
      {label}
    </LoadingButton>
  );
}
