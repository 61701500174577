import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { clientId } from 'src/constants/common';
import Routes from 'src/routes/routes';
import '../assets/scss/myScss/app.scss';
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
export default function App() {
  return (
    <div className="app">
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <Routes />
          </LocalizationProvider>
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </div>
  );
}
