import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import {
  createAlbumAdmin,
  getCategoriesMusic,
  getListMusicSpace,
  searchMusicApi
} from 'src/apis/socialMusic.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFiledUpdate';
import * as Yup from 'yup';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTab-iconWrapper': {
      margin: '0px 5px 0px 0px !important'
    }
  },
  dropzone: {
    position: 'relative',
    height: '200px',
    border: '2px dashed #ccc',
    textAlign: 'center',
    cursor: 'pointer'
  },
  icon: {
    '&:hover': {
      backgroundColor: '#eaeaea !important'
    }
  },
  image: {
    width: '100%',
    height: '17vh',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center'
  },
  mp3: {
    background: '#F2F2F2',
    width: '100%',
    height: '17vh',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
});

interface Props {
  openDialogCreate: boolean;
  setOpenDialogCreate: Dispatch<SetStateAction<boolean>>;
  setOpenSnackbar: Dispatch<SetStateAction<boolean>>;
  setNoti: Dispatch<SetStateAction<any>>;
}

export default function DialogCreateAlbum(props: Props) {
  const { setOpenDialogCreate, openDialogCreate, setOpenSnackbar, setNoti } =
    props;
  const [verify, setVerify] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [disableBtnAlbum, setDisableBtnAlbum] = React.useState<boolean>(false);
  const [listMusic, setListMusic] = React.useState<any>([]);
  const [errorImage, setErrorImage] = React.useState<boolean>(false);
  const [listCategory, setListCategory] = React.useState<any>([]);
  const [isLoadingCreateAlbum, setIsLoadingCreateAlbum] =
    React.useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = React.useState<boolean>(false);

  const getCategoriesMusicSpace = async () => {
    try {
      let res = await getCategoriesMusic();
      if (res.status === 200) {
        setListCategory(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListMusic = async () => {
    let res: any = await getListMusicSpace({ limit: 10 });
    if (res.status === 200) {
      let data = res.data.data.filter(item => item.status === 'approved');
      setListMusic(data);
    }
  };

  const debouncedHandleChangeKeywordSearch = React.useCallback(
    _.debounce(async value => {
      try {
        if (value) {
          let res = await searchMusicApi(value);
          if (res.status === 200) {
            let data = res.data.musics.filter(
              item => item.status === 'approved'
            );
            setListMusic(data);
            setIsLoadingSearch(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 600),
    []
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Vui lòng nhập tiêu đề')
      .matches(
        /^[^!@#$%^&*()?":{}|<>\/'\\;[\]=+_\-]*$/,
        'Tên tác phẩm không được chứa ký tự đặc biệt'
      )
      .max(200, 'Tên tác phẩm có ít hơn 200 ký tự')
      .nullable(),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (value?.file?.file) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    )
  });

  const handleCreateUpdateAlbum = async data => {
    try {
      let res = await createAlbumAdmin({
        title: data.title,
        banner_id: data?.banner?.file ? data.banner.file.id : data?.banner?.id,
        category_music: data.category_music,
        playlist_music: data.playlist_music.map((el: any) => el?.id) || null,
        description: data.description
      });
      if (res.status === 200) {
        setIsLoadingCreateAlbum(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Thêm mới Album thành công'
        });
        setOpenDialogCreate(false);
      }
    } catch (err) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Thêm mới album thất bại.'
      });
      setIsLoadingCreateAlbum(false);
    }
  };

  const handleClose = () => {
    setOpenDialogCreate(false);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: null,
      banner: null,
      category_music: [] as any,
      playlist_music: [] as any,
      description: null as any
    } as any,
    onSubmit: async (values: any) => {
      try {
        handleCreateUpdateAlbum(values);
      } catch (error) {
        console.log(error);
      }
    }
  } as any);

  let createAlbum: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Viết tên album',
      label: 'Tên album',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3
    },
    {
      field_type: 'textField',
      name: 'description',
      label: 'Mô tả ',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Mô tả nội dung chương trình phát',
      rows: 4,
      onChange: true
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*'
    },

    {
      field_type: 'autocomplete',
      name: 'category_music',
      options: listCategory,
      multiple: false,
      search: true,
      placeholder: 'Chọn thể loại',
      descriptionLabel: 'Bắt buộc*',
      label: 'Thể loại',
      renderListTag: true,
      status_item: 'active'
    },
    {
      field_type: 'autocomplete',
      name: 'playlist_music',
      options: listMusic,
      fetchData: debouncedHandleChangeKeywordSearch,
      multiple: true,
      search: true,
      placeholder: 'Thêm bài hát',
      label: 'Thêm bài hát',
      status_item: 'active',
      limitsTag: 3,
      loading: isLoadingSearch,
      setLoading: setIsLoadingSearch
    }
  ];

  React.useEffect(() => {
    if (
      formik.values.title &&
      formik.values.banner &&
      formik.values.category_music &&
      formik.values.description &&
      formik.values.playlist_music.length &&
      !Object.keys(formik.errors).length &&
      !errorImage
    ) {
      setDisableBtnAlbum(false);
    } else {
      setDisableBtnAlbum(true);
    }
  }, [formik.values]);

  React.useEffect(() => {
    fetchListMusic();
    getCategoriesMusicSpace();
  }, []);

  const renderSocialField = (setData, options) => {
    return (
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={setData}
        fieldUpdate={options}
        spacing={2}
        setErrorItem={setErrorImage}
      />
    );
  };

  return (
    <Box>
      <Dialog
        open={openDialogCreate}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none'
            }
          }}
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Typography variant="h6" gutterBottom>
              Tạo mới Album
            </Typography>
            {renderSocialField(formik, createAlbum)}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '20px'
              }}
            >
              <ButtonInherit
                label="Huỷ"
                style={{
                  marginTop: '12px',
                  padding: '0px 40px',
                  backgroundColor: '#7165E0',
                  color: '#fff'
                }}
                action={() => {
                  handleClose();
                }}
              />
              <ButtonInherit
                label={'Tạo album'}
                textColor="#fff"
                style={{
                  marginTop: '12px',
                  padding: '0px 40px'
                }}
                action={() => {
                  formik.handleSubmit();
                }}
                disabled={disableBtnAlbum}
                loading={isLoadingCreateAlbum}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
