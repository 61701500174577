import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

export const setCookie = value => {
  const encryptionKey = CryptoJS.lib.WordArray.random(128 / 8).toString(
    CryptoJS.enc.Hex
  );
  const encryptedToken = CryptoJS.AES.encrypt(value, encryptionKey).toString();

  Cookies.set('encryptedToken', encryptedToken, {
    // httpOnly: true, // Ngăn chặn truy cập bằng JavaScript
    // secure: true, // Chỉ cho phép truy cập qua HTTPS
    sameSite: 'Strict', // Giới hạn yêu cầu chéo trang
    expires: 7 // Thời gian sống của cookie (7 ngày)
  });

  Cookies.set('encryptionKey', encryptionKey, {
    // httpOnly: true, // Ngăn chặn truy cập bằng JavaScript
    // secure: true, // Chỉ cho phép truy cập qua HTTPS
    sameSite: 'Strict', // Giới hạn yêu cầu chéo trang
    expires: 7 // Thời gian sống của cookie (7 ngày)
  });
};

export const getCookie = () => {
  const encryptedToken = Cookies.get('encryptedToken');
  const encryptionKey = Cookies.get('encryptionKey');

  if (encryptedToken && encryptionKey) {
    // Giải mã token với khóa
    const decryptedToken = CryptoJS.AES.decrypt(
      encryptedToken,
      encryptionKey
    ).toString(CryptoJS.enc.Utf8);

    return decryptedToken;
  }
};

export const removeCookie = () => {
  Cookies.remove('encryptedToken');
  Cookies.remove('encryptionKey');
};
