import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApiAdmin = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/admin/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApi = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiV2 = async (endPoint, method, params, formData) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formData
  };

  let response = await axios(configs);
  return response;
};

export const getListMusicSpace = async params => {
  return fetchApiAdmin('musics', 'GET', params, null);
};

export const approveMusicSpace = async (idMusic, data: any) => {
  return fetchApiAdmin(`musics/${idMusic}`, 'PATCH', null, data);
};

export const getListAlbum = async params => {
  return fetchApiAdmin('playlists', 'GET', params, null);
};

export const approveAlbumSpace = async (idAlbum, data: any) => {
  return fetchApiAdmin(`playlists/${idAlbum}`, 'PATCH', null, data);
};

export const getListSoundsMoment = async params => {
  return fetchApiAdmin('sounds', 'GET', params, null);
};

export const createUpdateSoundMoment = async (data: any) => {
  var formdata = new FormData();
  formdata.append('name', data.name);
  formdata.append('author', data.author);

  if (data.media_id.id) {
    formdata.append('media_id', data.media_id.id);
  }
  if (data.banner.id) {
    formdata.append('banner_id', data.banner.id);
  }

  return fetchApiAdmin('sounds', 'POST', null, formdata);
};

export const getCategoriesMusic = async () => {
  return fetchApi('music_categories', 'GET', null, null);
};

export const createAlbumAdmin = async data => {
  const formdata = new FormData();
  formdata.append('name', data.title);
  formdata.append('description', data.description);
  if (data.banner_id) {
    formdata.append('banner_id', data.banner_id);
  }
  if (data.category_music) {
    formdata.append('music_category_id', data.category_music.id);
  }

  if (data.playlist_music.length) {
    for (var i = 0; i < data.playlist_music.length; i++) {
      formdata.append('playlist_music_ids[]', data.playlist_music[i]);
    }
  }

  return fetchApiAdmin('playlists', 'POST', null, formdata);
};

export const searchMusicApi = async (keyWord: any) => {
  return fetchApiV2(
    `search?type[]=musics&q=${encodeURIComponent(keyWord)}&limit=20&offset=0`,
    'GET',
    null,
    null
  );
};

export const approveMomentSort = async params => {
  return fetchApiAdmin(`approval_sounds`, 'PATCH', params, null);
};
