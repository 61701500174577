/* eslint-disable jsx-a11y/alt-text */ import {
  Box,
  Avatar
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import React, { useState } from 'react';
import DropdownArrow from './DropdownArrow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightHeader: {
      width: 300,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative'
    },
    wrapArrow: {
      position: 'absolute',
      display: 'flex',
      borderRadius: '50%',
      padding: 1,
      height: 14,
      width: 14,
      right: -5,
      bottom: 0,
      backgroundColor: '#ccccccc2'
    }
  })
);

function RightHeaderGlobal(props) {
  const classes = useStyles(props);
  const { meInfo } = props;
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  return (
    <Box className={classes.rightHeader}>
      <Avatar
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
        src={meInfo?.avatar_media?.show_url}
        alt="avatar_user"
        onClick={() => setOpenDropdown(!openDropdown)}
      />
      <Box className={classes.wrapArrow}>
        <i
          style={{ fontSize: 12, marginLeft: '2px' }}
          className="fa-solid fa-angle-down"
        ></i>
      </Box>
      {openDropdown && <DropdownArrow setOpen={setOpenDropdown} />}
    </Box>
  );
}
export default RightHeaderGlobal;
